import React, { Component } from 'react';
import GetImageURL from '../../../Utils/GetImageURL';
import AuthUserData from '../../../Model/Auth/AuthUser';
import AppServicesData from '../../../Model/Load/AppServices';
import HeaderLinksData from '../../../Model/Links/HeaderLinks';
import SupportLinksData from '../../../Model/Links/SupportLinks';
import LegalLinksData from '../../../Model/Links/LegalLinks';
import SocialMediaLinksData from '../../../Model/Links/SocialMediaLinks';

const onLoad = () => {
    window.scrollTo(0, 0);
};

const serviceConnect = () => {
    window.location.href = "/connect";
};

class HomePageComponent extends Component {
    render() {
        return (
            <div onLoad={onLoad}>
                <main>
                    <div className="position-relative overflow-hidden p-3 p-md-5 m-md-3 text-center bg-body-tertiary xc-home-header-body">
                        <div className="col-md-6 p-lg-5 mx-auto my-5">
                            <h1 className="display-3 fw-bold">Earn While You Learn</h1>
                            <h3 className="fw-normal text-muted mb-3">Our Innovative Interactive Learning Platform Rewards Your Educational Journey with Real Cash and Valuable Coins!</h3>
                            <br/>
                            <div className="d-flex gap-3 justify-content-center lead fw-normal">
                                <a className="btn btn-primary" href="help">Learn more</a>
                                {
                                    AuthUserData.isAuthenticated === "True" ?
                                        <a className="btn btn-primary" href="event">Let's Go</a>
                                        : <a className="btn btn-primary" href="connect">Connect</a>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="card text-center" style={{border: "none"}}>
                        <div className="card-body xc-card-body">
                            <img className="img-fluid text-center" width="500px" height="500px" src={GetImageURL("Verified")} alt="Categories" />
                            <p> &emsp; See&nbsp; 
                                <a style={{color: "blue", textDecoration: "none"}} href='https://www.iafcertsearch.org/certification/34GGDB8aRiOd0UfYMnS9XbTT' target="_blank" rel="noopener noreferrer">
                                    <i>Company's ISO Accredited Certification</i>
                                </a>
                            </p>
                        </div>
                    </div>    
                    <br/><br/><br/>
                    <h3 className="fw-normal text-muted text-center"><strong>How We Works</strong></h3>
                    <br/>
                    <div className="container text-center">
                        <div className="row align-items-center xc-home-banner">
                            <img className="img-fluid" src={GetImageURL("How-It-Works")} alt="How-It-Works" />
                        </div>
                    </div>
                    <br/>
                    <div className="container text-center">
                        <img className="img-fluid" src={GetImageURL("How-Works")} alt="How-Works" />
                    </div>
                    <br/><br/>
                    <h3 className="fw-normal text-muted text-center"><strong>Learn & Improve Your Skills</strong></h3>
                    <br/>
                    <div className="row row-cols-1 row-cols-md-3 g-4">
                        {
                            AppServicesData.map((item, index) => {
                                return (
                                    <div className="col" key={index} onClick={() => serviceConnect()}>
                                        <div className="card h-100 xc-home-events-card">
                                            <img src={GetImageURL(item.File)} className="card-img-top" alt={item.File} />
                                            <div className="card-body">
                                                <h5 className="card-title">{item.Title}</h5>
                                                <p className="card-text">{item.Description}</p>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </div>
                    <br/><br/><br/>
                    <h3 className="fw-normal text-muted text-center"><strong>Available Categories</strong></h3>
                    <br/>
                    <div className="container text-center">
                        <div className="row align-items-center">
                            <div className="col">
                                <div className="card xc-category-card">
                                    <div className="card-body text-center">
                                        <h4><strong>Computer Programming</strong></h4>
                                        <img className="img-fluid text-center" width="400px" height="400px" src={GetImageURL("Computer-Programming")} alt="Categories" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col">
                                <div className="card xc-category-card">
                                    <div className="card-body text-center">
                                        <h4><strong>Information Technology</strong></h4>
                                        <img className="img-fluid text-center" width="400px" height="400px" src={GetImageURL("Information-Technology")} alt="Categories" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col">
                                <div className="card xc-category-card">
                                    <div className="card-body text-center">
                                        <h4><strong>Science</strong></h4>
                                        <img className="img-fluid text-center" width="400px" height="400px" src={GetImageURL("Science")} alt="Categories" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col">
                                <div className="card xc-category-card">
                                    <div className="card-body text-center">
                                        <h4><strong>Mathematics</strong></h4>
                                        <img className="img-fluid text-center" width="400px" height="400px" src={GetImageURL("Mathematics")} alt="Categories" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col">
                                <div className="card xc-category-card">
                                    <div className="card-body text-center">
                                        <h4><strong>Current Affairs</strong></h4>
                                        <img className="img-fluid text-center" width="400px" height="400px" src={GetImageURL("Current-Affairs")} alt="Categories" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br/><br/><br/>
                    <div className="container text-center">
                        <div className="row align-items-center xc-home-banner">
                            <img className="img-fluid text-center" src={GetImageURL("Updates")} alt="Updates" />
                        </div>
                    </div>
                </main>
                <br/><br/>
                <footer className="container py-5">
                    <div className="row">
                        <div className="col-12 col-md">
                            <p className="d-block mb-3 text-body-secondary"><strong>&copy; XCOLON Inc.</strong></p>
                            <p className="d-block mb-3 text-body-secondary">All Rights Reserved</p>
                            <a className="link-secondary text-decoration-none" target="_blank" rel="noopener noreferrer" href="https://www.xcolon.in">
                                <p className="d-block mb-3 text-body-secondary">Our Company &nbsp; <i className="fa fa-caret-right"></i></p>
                            </a>
                        </div>
                        <div className="col-6 col-md">
                            <h5>Learn & Earn</h5>
                            <ul className="list-unstyled text-small">
                                {
                                    HeaderLinksData.map((item, index) => {
                                        return (
                                            // <li key={index}><a className="link-secondary text-decoration-none" href={item.route}>{item.title}</a></li>
                                            <li key={index}><a className="link-secondary text-decoration-none" href="connect">{item.title}</a></li>
                                        );
                                    })
                                }
                            </ul>
                        </div>
                        <div className="col-6 col-md">
                        <h5>Support</h5>
                        <ul className="list-unstyled text-small">
                            {
                                SupportLinksData.map((item, index) => {
                                    return (
                                        <li key={index}><a className="link-secondary text-decoration-none" href={item.route}>{item.title}</a></li>
                                    );
                                })
                            }
                        </ul>
                        </div>
                        <div className="col-6 col-md">
                        <h5>Legal</h5>
                        <ul className="list-unstyled text-small">
                            {
                                LegalLinksData.map((item, index) => {
                                    return (
                                        <li key={index}><a className="link-secondary text-decoration-none" href={item.route}>{item.title}</a></li>
                                    );
                                })
                            }
                        </ul>
                        </div>
                        <div className="col-6 col-md">
                        <h5>Community</h5>
                        <ul className="list-unstyled text-small">
                            {
                                SocialMediaLinksData.map((item, index) => {
                                    return (
                                        <li key={index}><a className="link-secondary text-decoration-none" target="_blank" rel="noopener noreferrer" href={item.url}>{item.title}</a></li>
                                    );
                                })
                            }
                        </ul>
                        </div>
                    </div>
                </footer>
            </div>
        )
    }
}
export default HomePageComponent